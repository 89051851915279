<template>
	<div class="bullMarket-supervise">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-select v-model:value="search.re_ty" style="width: 180px;">
					<a-select-option value="">请选择关联类型</a-select-option>
					<a-select-option value="create_user">会员</a-select-option>
					<a-select-option value="recharge">充值</a-select-option>
					<a-select-option value="withdrawal">提现</a-select-option>
					<a-select-option value="animal_order">订单</a-select-option>
				</a-select>
				 <a-select v-model:value="search.sys" placeholder="系统类型" style="width: 180px;">
				 	<a-select-option value="">系统类型</a-select-option>
				 	<a-select-option value="ceb">资金系统</a-select-option>
				 	<a-select-option value="fcs">监管系统</a-select-option>
				 </a-select>
				<a-input-search style="width: 400px;" v-model:value="search.re_id" placeholder="关联提现id、充值id、畜牧id、订单id" enter-button @search="getCebReqList(1,info.limit)" />
			</a-space>
		</div>
		
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'系统类型',dataIndex:'sys',slots:{customRender:'sys'}},
				{title:'关联类型',dataIndex:'re_type',slots:{customRender:'re_type'}},
				{title:'请求接口',dataIndex:'uri'},
				{title:'请求时间',dataIndex:'create_time'},
			]" >
				<template #sys="{record}">
					<a-tag color="#FF0066" v-if="record.sys == 'ceb'">资金系统</a-tag>
					<a-tag color="#00CC66" v-if="record.sys == 'fcs'">监管系统</a-tag>
				</template>
				<template #re_type="{record}">
					<span style="color: #ffaa7f;" v-if="record.re_type == 'create_user'">会员</span>
					<span style="color: #00aa00;" v-if="record.re_type == 'recharge'">充值</span>
					<span style="color: #ff5500;" v-if="record.re_type == 'withdrawal'">提现</span>
					<span style="color: #5555ff;" v-if="record.re_type == 'animal_order'">订单</span>
				</template>
				<template #expandedRowRender="{ record }">
					<div style="display: flex;">
						<div style="width: 50%;">
							<h4>请求参数</h4>
							<div style="word-wrap: break-word;word-break: normal;"><pre>{{record.request}}</pre></div>
						</div>
						<div>
							<h4>响应数据</h4>
							<div><pre>{{record.response}}</pre></div>
						</div>
					</div>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getCebReqList(info.page,e)}"
					@change="(e)=>{getCebReqList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import bullModel from '@/api/addons/bull'
export default{
	setup(){
		const state = reactive({
			search:{
				re_ty:'',
				re_id:'',
				sys:''
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			}
		})
		const opt = useRoute().query
		if( opt.re_ty ) state.search.re_ty = opt.re_ty
		if( opt.re_id ) state.search.re_id = opt.re_id

		getCebReqList(1,state.info.limit)

		function getCebReqList(page,limit){
			bullModel.getCebReqList(page,limit,state.search,res=>{
				state.info = {limit,...res}
				state.info.list.forEach(item=>{
					item.request =JSON.parse(item.request)
					if( item.sys == 'fcs' ){
						item.response = formateXml(item.response)
					}
				})
			})
		}
		
		//计算头函数 用来缩进
		function setPrefix(prefixIndex) {
			var result = '';
			var span = '    ';//缩进长度
			var output = [];
			for(var i = 0 ; i < prefixIndex; ++i){
				output.push(span);
			}
			result = output.join('');
			return result;
		}

		function formateXml(xmlStr){
			let text = xmlStr;
			//使用replace去空格
			text = '\n' + text.replace(/(<\w+)(\s.*?>)/g,function($0, name, props){
				return name + ' ' + props.replace(/\s+(\w+=)/g," $1");
			}).replace(/>\s*?</g,">\n<");
			//处理注释
			text = text.replace(/\n/g,'\r').replace(/<!--(.+?)-->/g,function($0, text){
				var ret = '<!--' + escape(text) + '-->';
				return ret;
			}).replace(/\r/g,'\n');
			//调整格式  以压栈方式递归调整缩进
			var rgx = /\n(<(([^\?]).+?)(?:\s|\s*?>|\s*?(\/)>)(?:.*?(?:(?:(\/)>)|(?:<(\/)\2>)))?)/mg;
			var nodeStack = [];
			var output = text.replace(rgx,function($0,all,name,isBegin,isCloseFull1,isCloseFull2 ,isFull1,isFull2){
				var isClosed = (isCloseFull1 == '/') || (isCloseFull2 == '/' ) || (isFull1 == '/') || (isFull2 == '/');
				var prefix = '';
				if(isBegin == '!'){//!开头
					prefix = setPrefix(nodeStack.length);
				}else {
					if(isBegin != '/'){///开头
						prefix = setPrefix(nodeStack.length);
						if(!isClosed){//非关闭标签
							nodeStack.push(name);
						}
					}else{
						nodeStack.pop();//弹栈
						prefix = setPrefix(nodeStack.length);
					}
				}
				var ret =  '\n' + prefix + all;
				return ret;
			});
			var prefixSpace = -1;
			var outputText = output.substring(1);
			//还原注释内容
			outputText = outputText.replace(/\n/g,'\r').replace(/(\s*)<!--(.+?)-->/g,function($0, prefix,  text){
				if(prefix.charAt(0) == '\r')
					prefix = prefix.substring(1);
				text = unescape(text).replace(/\r/g,'\n');
				var ret = '\n' + prefix + '<!--' + text.replace(/^\s*/mg, prefix ) + '-->';
				return ret;
			});
			outputText= outputText.replace(/\s+$/g,'').replace(/\r/g,'\r\n');
			return outputText;
		}

		return {
			...toRefs(state),
			getCebReqList,
			setPrefix,
			formateXml
		}
	}
}
</script>

<style>
</style>
